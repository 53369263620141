.intr_This {
    width: 100%;
}

.intr_Box {
    width: 100%;
    height: 100%;
    margin-bottom: 90px;
}

.intr_Label {
    width: 488px;
	height: 40px;
	border-bottom: 2px solid #28465e;
    font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 22px;
	letter-spacing: 0px;
    color: #252525;
    margin-bottom: 50px;
}

.intr_Content {
    display: flex;
    justify-content: space-between;
}

.intr_Left {
    max-width: 554px;
    min-width: 440px;
}

.intr_Left .intr_leftContent:first-child .intr_subTitle {
    padding-top: 0;
    margin-top: 0;
}

.intr_Left .intr_leftContent:nth-child(2n) .intr_subTitle {
    border-top: 3px dotted #252525;
}

.intr_subTitle {
    font-size: 22px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 22px;
	letter-spacing: 0px;
    color: #28465e;
    padding-top: 39px;
    margin-top: 40px;
    width: 635px;
}

.intr_Content .intr_subLabel:first-child {
    margin-top: 0px;
}

.intr_subLabel {
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold;
	font-weight: bold;
	font-stretch: normal;
	line-height: 22px;
	letter-spacing: 0px;
    color: #28465e;
    margin: 39px 0 8px 0;
}

.intr_info {
    width: 100%;
    font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 22px;
	letter-spacing: 0px;
    color: #252525;
    margin-bottom: 8px;
}

.guanyu_logo1 {
    max-width: 16%;
    width: 320px;
    height: 380px;
    background: url('../../image/guanyu_logo.png') no-repeat;
    background-size: contain;
    margin-right: 200px;
}

.guanyu_logo2 {
    width: 590px;
    height: 398px;
    background: url('../../image/rongyu_jpg.jpeg') no-repeat;
    background-size: contain;
}

.guanyu_logo3 {
    width: 538px;
    height: 389px;
    background: url('../../image/jiaruluoshi_png.png') no-repeat;
    background-size: contain;
}

.guanyu_logo4 {
    width: 486px;
    height: 517px;
    background: url('../../image/zhaopin_png.png') no-repeat;
    background-size: contain;
}