.caseBanner {
    width: 1200px;
    margin: 0 auto;
    margin-top: 170px;
    margin-bottom: 130px;

    :global(.slick-dots) {
        top: -64px;
        bottom: unset;
    }
    :global(.slick-dots li) {
        width: 30px !important;
    }
    :global(.slick-dots li button) {
        width: 16px !important;
        height: 16px !important;
        border-radius: 50% !important;
        background-color: #28465e;
        opacity: 0.5;
    }
    :global(li.slick-active button) {
        background: #28465e !important;
    }
}

.item {
    width: 1200px;
    cursor: pointer;
    img {
        width: 1200px;
    }
}

