.viewer {
  .banner {
      .bannerInner {
          display: flex;
          justify-content: center;
      }
      .left {
          position: relative;
          width: 327px;
          height: 502px;
          background-color: #b9375b;
          margin-right: 20px;
          padding: 0 20px;
          .avatar {
            display: block;
            width: 130px;
            height: 130px;
            margin: 0 auto;
            margin-top: 30px;
            border-radius: 50%;
            background-color: #fff;
          }
          h4 {
            height: 23px;
            font-size: 22px;
            line-height: 22px;
            text-align: center;
            color: #ffffff;
            font-family: MicrosoftYaHei;
            margin-top: 17px;
          }
          .desc {
            height: 180px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            font-size: 12px;
            line-height: 36px;
            margin-top: 30px;
            color: #ffffff;
            font-family: MicrosoftYaHei;
          }
          .platform {
            position: absolute;
            display: flex;
            justify-content: start;
            bottom: 38px;
            left: 20px;
            img {
              width: 38px;
              height: 38px;
              border-radius: 6px;
              margin-right: 15px;
              cursor: pointer;
            }
          }
      }
      .right {
          width: 852px;
          height: 502px;
          background-color: #f1f1f1;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
      }
  }
  .slider {
      margin-top: 34px;
      height: 127px;
      // overflow: hidden;
      .item {
          width: 257px;
          height: 127px;
          padding: 0 6px;
          .img {
            display: block;
            width: 100%;
            min-width: 200px;
            height: 127px;
            background-color: #f1f1f1;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
      }
      .prevArrow {
        position: absolute;
        top: 50%;
        left: -40px;
        margin-top: -7px;
        width: 4px;
        height: 0px;
        border-width: 10px 12px;
        cursor: pointer;
        border-style: solid;
        border-color: transparent #444 transparent transparent;
      }
      .nextArrow {
        position: absolute;
        top: 50%;
        right: -40px;
        margin-top: -7px;
        width: 4px;
        height: 0px;
        border-width: 10px 12px;
        cursor: pointer;
        border-style: solid;
        border-color: transparent transparent transparent #444;
      }
  }
}