.home_This {
    width: 100%;
    height: 100%;
}

.home_top {
    margin: 150px auto 0 auto;
    overflow: hidden;
}

.home_Label {
    width: 100%;
	height: 32px;
	font-family: MicrosoftYaHei-Bold;
	font-size: 30px;
	font-weight: normal;
	font-stretch: normal;
    line-height: 32px;
    text-align: center;
	letter-spacing: 4px;
    color: #252525;
    position: relative;
    margin: 0 auto 90px auto;
}

.home_Label::after {
    content: '';
    width: 100px;
	height: 6px;
	background-color: #28465e;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -30px;
}

.home_List {
    width: 100%;
    display: flex;
    justify-content: center;
}

.home_item {
    max-width: 256px;
    width: calc((100% - 224px) / 4);
	height: 365px;
	background-color: #f8f8f8;
    border-radius: 20px;
    float: right;
    margin: 0 28px;
}

.home_pinpai {
    width: 1198px;
	height: 657px;
	border: solid 1px #a7a7a7;
    background: url(../../image/pinpai_logo.jpeg);
    margin: 0 auto 198px auto;
}