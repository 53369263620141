.cancat {
    width: 1200px;
    margin: 0 auto;
    padding-top: 140px;
    h1 {
        width: 490px;
        line-height: 26px;
        font-family: MicrosoftYaHei-Bold;
        font-size: 26px;
        letter-spacing: 0px;
        color: #252525;
        padding-bottom: 20px;
        border-bottom: 2px solid #28465e;
        margin-bottom: 67px;
    }
    .info {
        font-family: MicrosoftYaHei;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 0px;
        color: #252525;
        margin-bottom: 30px;
        span {
            letter-spacing: 0px;
            color: #28465e;
            font-weight: bold;
        }
    }
    .map {
        width: 1200px;
        height: 740px;
        margin: 120px auto;
    }
}

