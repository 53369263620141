.about_this {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.about_top {
    width: 1200px;
    margin: 136px auto;
}

.about_bottom {
    width: 100%;
    width: 100%;
    background: url('../../image/yewu_bg.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 140px;
}

.about_bottomTitle {
    width: 100%;
    height: 62px;
    text-align: center;
    font-size: 30px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 23px;
	letter-spacing: 4px;
    color: #252525;
    position: relative;
    margin-bottom: 62px;
}

.about_bottomTitle::after {
    content: '';
    width: 100px;
	height: 4px;
	background-color: #28465e;
    border-radius: 3px;
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translate(-50%, 0);
}

.about_bottomBox {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin: 145px auto 119px auto;
}

.about_bottomInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about_bottomInfo img {
    width: 102px;
    height: 116px;
}

.about_bottomKey {
    font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 28px;
	letter-spacing: 0px;
    color: #333333;
    margin-top: 40px;
    margin-bottom: 20px;
}

.about_bottomValue {
    font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 28px;
	letter-spacing: 0px;
    color: #333333;
    padding: 0 24px;
}