.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 1300px;
}

.inner {
  width: 1200px;
  margin: 0 auto;
}

.header {
  width:100%;
  min-width: 1200px;
}

.header .menu{
  display: flex;
  align-items: center;
  width: 1200px;
  height: 120px;
  color: #28465e;
  margin: 0 auto;
}

.header .menu .logo {
  /* width: 222px; */
  height: 70px;
  margin-right: 320px;
}

.header .menu .item {
  font-size: 15px;
  cursor: pointer;
  margin-right: 60px;
}

.header .menu .active {
  font-weight: bold;
}

.header .swiper {
  max-width: 1920px;
  width: 100%;
  background: #fff;
  margin: 0 auto;
}

.header .swiper-item {
  height: 500px;
}

.swiper-item img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.content {
  width: 100%;
  min-width: 1200px;
}

.footer {
  width: 100%;
  min-width: 1200px;
  background-color: #172836;
}

.footer .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
	height: 430px;
  color: #d7d7d7;
  box-sizing: border-box;
  background-color: #172836;
}

.footer_left .logo {
  display: flex;
  align-items:  center;
}

.footer_left .logo img {
  width: 193px;
  height: 79px;
  margin-right: 98px;
}

.footer_left .desc {
  width: 482px;
  margin-top: 77px;
  color: #d7d7d7;
  margin-top: 85px;
  height: 100px;
	font-family: MicrosoftYaHei;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 29px;
	letter-spacing: 1px;
}

.footer_center {
  width: 73px;
  height: 280px;
  position: relative;
}

.footer_center::before {
  content: '';
  width: 1px;
  height: 160px;
  position: absolute;
  top: 50%;
  left: -100px;
  transform: translateY(-50%);
  background-color: #979797;
}

.footer_center::after {
  content: '';
  width: 1px;
  height: 160px;
  position: absolute;
  top: 50%;
  right: -100px;
  transform: translateY(-50%);
  background-color: #979797;
}

.footer_center .item {
  width: 72px;
	height: 17px;
	font-family: MicrosoftYaHei;
  font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 23px;
	letter-spacing: 0px;
	color: #ffffff;
  margin-bottom: 47px;
  cursor: pointer;
}

.footer_center .item:last-child {
  margin-bottom: 0;
}

.footer_right {
  width: 232px;
  height: 280px;
  position: relative;
}

.footer_right .label {
  font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 23px;
	letter-spacing: 1px;
	font-family: MicrosoftYaHei;  
	color: #ffffff;
  margin-bottom: 43px;
}

.footer_right .phone {
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
}




