.juzhen {
  width: 100%;
}
.sourceViewRow {
  width: 1200px;
  margin: 0 auto;
  .subTitle {
    text-align: center;
    font-family: MicrosoftYaHei;
    padding: 94px 0 80px 0;
    h3 {
      position: relative;
      font-family: MicrosoftYaHei-Bold;
      font-size: 30px;
      letter-spacing: 4px;
      color: #28465e;
      padding-bottom: 25px;
      box-sizing: content-box;
      &::after {
        content: "";
        width: 60px;
        height: 4px;
        background-color: #28465e;
        border-radius: 3px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }
    p {
      margin: 20px 0 0 0;
      font-family: MicrosoftYaHei;
      height: 20px;
      font-size: 12px;
      line-height: 22px;
      letter-spacing: 0px;
      color: #252525;
    }
  }
  .userList {
    width: 100%;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    padding-bottom: 80px;
    .userItem {
      text-align: center;
      width: 140px;
      height: 240px;
      margin-right: 72px;
      &:nth-child(6n) {
        margin-right: 0;
      }
      .avatar {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin: 0 auto;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #f1f1f1;
        cursor: pointer;
      }
      .name {
        height: 24px;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 28px;
        letter-spacing: 0px;
        color: #333333;
        margin-top: 24px;
        cursor: pointer;
      }
    }
  }
  .platformList {
    width: 754px;
    height: 214px;
    margin: 0 auto;
    margin-bottom: 70px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
