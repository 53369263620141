.job-name {
	height: 23px;
	font-family: MicrosoftYaHei-Bold;
	font-size: 22px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 22px;
	letter-spacing: 0px;
    color: #28465e;
    margin-top: 50px;
}

.intr_Label {
    width: 488px;
	height: 51px;
	border-bottom: 2px solid #28465e;
    font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 22px;
	letter-spacing: 0px;
    color: #252525;
    margin-bottom: 69px;
}

.job-list .job-item:not(:first-child){
    margin-top: 70px;
    border-top: 3px dotted #252525;
}